@import "./mixins.scss";

.serviceItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;

  &__options {
    display: flex;
    flex-direction: column;
    padding: 84px 16px 16px 16px;
    align-items: flex-start;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    margin-top: -68px;
  }

  &__optionsHeading {
    color: #57ae61;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.4px;
    margin-bottom: 16px;
  }

  &__service {
    display: flex;
    padding: 25px 25px 25px 54px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;

    @include md {
      flex-direction: column;
      padding: 16px;
      gap: 16px;
    }
  }

  &__greenSide {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 42px;
    background: #65ae6b;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &__sideIcon {
    position: absolute;
    left: 11px;
    top: 50%;
    z-index: 100;
    transform: translateY(-50%);
    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    height: 65px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
    margin-left: 10px;
    max-width: 70%;
    margin-right: 10px;
  }

  &__mini {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    @include md {
      justify-content: space-between;
    }
  }

  &__title {
    align-self: stretch;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: -0.36px;
    text-transform: capitalize;

    @include lg {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.32px;
    }

    @include md {
      align-self: center;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -0.36px;
    }
  }

  &__description {
    align-self: stretch;
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.9px;

    @include lg {
      font-size: 12px;
      line-height: 15px;
    }

    @include md {
      font-size: 13px;
      line-height: 16.9px;
    }
  }

  &__btn {
    display: flex;
    padding: 16px 24px;
    justify-content: flex-start;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #65ae6b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &--selected {
      background: #284d2c;
    }

    @include lg {
      font-size: 14px;
      line-height: 14px;
      padding: 16px 8px;
    }

    @include md {
      width: 100%;
      font-size: 16px;
      line-height: normal;
    }
  }

  &__moreInfo {
    color: #888;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.3px;
    width: 100%;
    text-align: center;
  }

  &__mobileInfoBtn {
    background: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    justify-self: flex-end;
    padding: 0;

    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__mobileServiceLeft {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: center;
    margin-bottom: 8px;
  }
}

.inputGroup {
  display: flex;
  padding: 7px 14px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 41px;
  border: 1px solid rgba(9, 76, 54, 0.06);
  margin-bottom: 8px;

  .originalPrice {
    color: #808080;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.6px;
    text-decoration-line: line-through;
  }

  &__price {
    color: #094c36;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.2px;
  }

  &__side {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__radio {
    appearance: none;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #65ae6b;
    background: #ececec33;
    margin: 0;
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px;
      height: 10px;
      border-right: 2px solid #65ae6b;
      border-bottom: 2px solid #65ae6b;
      transform: translate(-50%, -60%) rotate(45deg);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:checked::before {
      opacity: 1;
    }
  }

  &__lbl {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.2px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .addSeedingBtn {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    padding: 0;
    gap: 10px;

    span {
      color: rgba(5, 38, 27, 0.71);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18.2px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}


.whiteGrubIcon {
  width: 100%;
  height: 100%;
}