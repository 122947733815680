@import "./mixins.scss";

.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4.375rem;
  margin-bottom: 3.75rem;
  position: relative;
  width: 540px;
  // padding: 0 10px;

  @include md {
    background: transparent;
    margin-bottom: 0;
    padding-bottom: 24px;
    width: calc(100vw - 32px);
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    gap: 0;
  }

  &__line {
    position: absolute;
    left: 22px;
    top: 21px;
    transform: translateY(-50%);
    height: 0.125rem;
    width: 490px;
    z-index: 50;
    background-color: rgba(94, 173, 103, 0.17);

    @include md {
      background-color: #65ae6b;
      width: calc(100% - 32px);
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    z-index: 100;
    position: relative;
    cursor: pointer;

    &__text {
      font-weight: 600;
      font-size: 13.5px;
      line-height: 14.85px;
      color: #ffffff;
      @include md {
        color: #65ae6b;
        font-size: 10px;
      }

      &--inActive {
        color: #ffffff4d;

        @include md {
          color: #65ae6b;
        }
      }
    }
  }

  .step {
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: white;
    color: #60b067;
    transition: background-color 0.3s;

    @include md {
      border: 1px solid #65ae6b;
    }

    &.active {
      background-color: #65ae6b;
      color: white;
    }
  }
}
