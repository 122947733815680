@import "./mixins.scss";

.selectedServices {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-bottom: 22px;

  .ss__header {
    color: #65ae6b;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.7px;
    text-transform: uppercase;

    @include xl {
      font-size: 14px;
      line-height: 20.8px;
    }

    // @include lg {
    //   font-size: 16px;
    //   line-height: 20.8px;
    // }

    @include md {
      font-size: 12px;
      line-height: 18.2px;
    }

    @include sm {
      font-size: 12px;
      line-height: 15.6px;
    }
  }

  .ss__num {
    color: #000;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.7px;
    text-transform: uppercase;
    border: 2px solid #65ae6b;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include xl {
      font-size: 14px;
      line-height: 20.8px;
    }

    @include md {
      font-size: 12px;
      line-height: 18.2px;
    }

    @include sm {
      font-size: 12px;
      line-height: 15.6px;
    }
  }
}

.service {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 13px;
  width: 100%;

  @include xl {
    gap: 10px;
  }

  @include md {
    gap: 5px;
  }

  @include sm {
    gap: 2px;
  }

  &__name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.8px;
    color: black;

    &--gray {
      color: #7d7d7d;
      opacity: 0.9;
    }

    @include xl {
      font-size: 14px;
      line-height: 20.8px;
    }

    @include md {
      font-size: 12px;
      line-height: 18.2px;
    }

    @include sm {
      font-size: 12px;
      line-height: 15.6px;
    }
  }
}

.dashLine {
  border-bottom: 1.275px dashed rgba(0, 0, 0, 0.1);
  height: 1.275px;
  flex: 1;
}

.greenDivider {
  height: 1.275px;
  background: #65ae6b;
  width: 100%;
  margin: 32px 0;
  display: block;

  @include md {
    margin: 11px 0 24px 0;
  }

  @include sm {
    margin: 16px 0 16px 0;
  }
}

.bullet {
  &::before {
    content: "• ";
    display: inline;
  }
}

.discountPriceText {
  color: #808080;
  font-size: 14px;
  text-decoration-line: line-through;
  font-weight: 500;
  line-height: 15.6px;

  @include xl {
    font-size: 11px;
  }

  @include sm {
    font-size: 10px;
  }
}