@import "./mixins.scss";

.header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  height: 5rem;
  padding: 0.625rem 5rem 0.625rem 5rem;
  background-color: #ffffff;

  @include xl {
    padding: 0.625rem 2rem 0.625rem 2rem;
  }

  .logoImage {
    justify-self: start;
    align-self: center;

    cursor: pointer;

    flex-shrink: 0;

    width: 232.5px;
    height: 37.2px;

    @include md {
      width: 155.494px;
      height: 24.88px;
      margin-left: 16px;
    }
  }

  @include md {
    display: flex;
    height: 64px;
    max-height: 64px;
    width: 100%;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    .sidebar {
      position: fixed;
      background-color: #ffffff;
      top: 0;
      right: 0;
      width: 70%;
      height: 100vh;
      z-index: 10000;
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      padding-left: 12px;
      padding-right: 12px;

      transition: all 0.35s;

      &__separator {
        height: 1px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: rgba(0, 0, 0, 0.08);
      }

      &__closeButtonContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 16px;
        padding-bottom: 6px;
        padding-right: 12px;
      }

      &__closeButton {
        height: 32px;
        background-color: #65ae6c;
        color: #ffffff;
        border: none;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s;
        padding: 9px 15px;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          background-color: #4d8f4a;
        }
      }
    }
  }

  .hamburger {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    transition: all 0.35s;

    &:hover {
      border-radius: 50%;
      background-color: #e4f8f2;
    }
  }

  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: 4px;
    grid-row-gap: 4px;

    @include md {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background: #fff;
      gap: 8px;
    }

    &__dot {
      width: 5px;
      height: 5px;
      min-width: 5px;
      min-height: 5px;
      background-color: rgba(9, 76, 53, 0.12);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #1e1e1e;
      transition: color 0.3s;
      height: 32px;
      color: #094c36;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      transition: all 0.35s;
      white-space: nowrap;
      letter-spacing: -0.02em;
      border-radius: 32px;
      border: 1px solid rgba(0, 0, 0, 0);

      @include md {
        height: 64px;
        width: 100%;
        justify-content: flex-start;
        border-radius: 6px;
      }

      span {
        font-weight: 600;
        font-size: 0.9375rem;
        line-height: 1.1344rem;
      }

      &:hover {
        background-color: #e4f8f2;
      }
    }
  }

  .rightSide {
    justify-self: end;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 35px;

    @include xl {
      gap: 20px;
    }

    &__separator {
      width: 0.0625rem;
      height: 1.8125rem;
      border-left: 1.5px solid #0000001a;
    }

    &__cta {
      background-color: #65ae6b;
      color: #ffffff;
      font-weight: 600;
      font-size: 0.9375rem;
      line-height: 1.1344rem;
      border-radius: 2.5rem;
      padding: 0.875rem 1.5625rem 0.875rem 1.5625rem;
      border: none;
      white-space: nowrap;

      &:hover {
        background-color: #4d8f4a;
        cursor: pointer;
      }

      @include xl {
        padding: 0.875rem 1rem 0.875rem 1rem;
      }
    }
  }

  .languageSwitcher {
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 8px;
    border-radius: 60px;
    border: 1.5px solid #0000001a;

    @include md {
      width: fit-content;
      margin-bottom: 10px;
    }

    &__separator {
      height: 1.125rem;

      border-left: 1.5px solid rgba(0, 0, 0, 0.12);
    }

    &__globeIcon {
      width: 20px;
      height: 20px;
    }

    .languageButton {
      background: none;
      border: none;
      color: #1e1e1e66;
      margin: 0 0.5rem;
      cursor: pointer;
      transition: color 0.3s;
      line-height: 1.1344rem;
      font-size: 15px;
      font-weight: 500;

      &--selected {
        color: #1e1e1e;
      }

      &:hover {
        color: #65ae6b;
      }
    }
  }
}
