@import "./mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.annualPlan {
  display: flex;
  height: 1.75rem;
  padding: 0rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 2.6875rem;
  border: 0.125rem solid #fff;
  color: #fff;
  text-align: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.0562rem;
  text-transform: uppercase;
  width: fit-content;
  margin-bottom: 0.8125rem;

  @include md {
    margin-top: 24px;
    font-size: 10px;
  }
}

.heading {
  color: #fff;
  font-size: 3.1875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3.5063rem;
  letter-spacing: -1.02px;
  margin-top: 0;
  margin-bottom: 1.5625rem;

  @include md {
    font-size: 24px;
    line-height: 26.4px;
    letter-spacing: -0.48px;
    margin-bottom: 24px;
  }
}

.explanation {
  width: 42.625rem;
  color: #fff;
  text-align: center;
  font-size: 1.1875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7813rem;
  letter-spacing: -0.38px;
  margin-top: 0;
  margin-bottom: 40px;

  @include md {
    font-size: 12px;
    width: 90%;
    line-height: 18px;
    letter-spacing: -0.24px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 2rem;

  &__btn {
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &--confirm {
      width: 12.125rem;
      padding: 1rem 2.5rem;
      background: #65ae6b;
      gap: 0.625rem;
      border: none;
    }

    &--back {
      padding: 16px 25px;
      gap: 8px;
      border: 2px solid #fff;
      background: transparent;
    }
  }
}

.scheduleGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 3.75rem;
  gap: 1.875rem;
  padding-top: 20px;

  @include xl {
    display: flex;
    flex-direction: row;
    overflow: auto;
    gap: 30px;
    margin-bottom: 30px;
    max-width: 95vw;
    padding-left: 24px;
    padding-bottom: 20px;
  }
}


.menuBar {
  &__priceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
  }

  &__totalPrice {
    color: #094c36;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
  }

  &__monthlyTotalPrice {
    color: #888;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.3px;
  }

  &__priceDetailsBtn {
    color: #65ae6b;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.3px;
    border: none;
    background: none;
    cursor: pointer;
  }

  &__continueBtn {
    display: flex;
    width: 100%;
    padding: 16px 48px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #65ae6b;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 16px;

    &:hover {
      opacity: 0.8;
    }
  }
}
