@import './mixins.scss';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  border-radius: 24px;
  max-width: 70%;
  height: fit-content;
  max-height: 90%;
  z-index: 2001;
  overflow: hidden;

  @include lg {
    max-width: 80%;
  }

  @include md {
    max-width: 85%;
  }

  @include sm {
    max-width: 95%;
  }
}

.close-button {
  position: absolute;
  top: 30px;
  right: 25px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 2003;

  &:hover {
    opacity: 0.8;
  }

  @include md {
    top: 20px;
    right: 20px;
  }

  @include sm {
    top: 10px;
    right: 10px;
  }
}
