@import "./mixins.scss";

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 70vh;
  // overflow: auto;

  @include md {
    display: flex;
    flex-direction: column;
    height: 80vh;
  }

  gap: 10px;

  p {
    margin: 0;
  }

  &__col {
    display: flex;
    flex-direction: column;
    padding: 80px;
    padding-bottom: 0;
    gap: 40px;
    align-items: flex-start;
    border-radius: 20px;
    background: #65ae6b;
    overflow: auto;

    @include xl {
      padding: 60px;
      gap: 30px;
    }

    @include lg {
      padding: 40px;
      gap: 20px;
    }

    @include md {
      padding: 20px;
      gap: 10px;
      height: 100%;
    }

    &--r {
      background: #fff;
      gap: 10px;
      padding: 0;
      justify-content: space-between;
    }
  }

  &__img {
    height: auto;
    width: 100%;
    border-radius: 20px;
  }

  &__btns {
    display: flex;
    border-radius: 24px;
    height: 42px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.24);
    overflow: hidden;
    min-height: 42px;
  }

  &__btn {
    color: #1e1e1e;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0 24px;
    z-index: 10000;

    &:hover {
      opacity: 0.8;
    }

    &--selected {
      color: #fff;
      background: #094c36;
    }

    @include lg {
      font-size: 13px;
    }

    @include md {
      font-size: 10px;
    }
  }

  h2 {
    align-self: stretch;
    color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 3.6rem;
    margin: 0;

    @include lg {
      font-size: 30px;
    }

    @include md {
      font-size: 20px;
    }
  }

  &__description {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.24px;
    letter-spacing: -0.16px;

    &--indented {
      padding-left: 20px;
    }
  }
}

.card {
  display: flex;
  padding: 10px 16px 10px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__icon {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  &__title {
    color: #1e1e1e;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
  }

  &__messages {
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3px;
    align-self: stretch;
    margin: 0;
  }

  &__message {
    color: #1e1e1e;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px;
    margin-left: 20px;
  }
}

.carouselImg {
  width: 100%;
  border-radius: 20px;

  img {
    object-fit: cover;
    height: 300px;
    border-radius: 20px;
  }
}

.carousel {
  button {
    opacity: 1 !important;
  }
}
