@import './mixins.scss';

.scheduleItem {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 1.875rem 1.8125rem;
  flex-direction: column;
  align-items: center;
  border-radius: 0.9375rem;
  background: #efefef;

  &--inModal {
    height: 410px;
  }

  &__topText {
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    padding: 0.3571rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.7143rem;
    border-radius: 2.1429rem;
    background: #ea6d01;
    color: #fff;
    text-align: center;
    font-size: 10.594px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  &__title {
    color: #212121;
    text-align: center;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1.375rem;
    text-transform: capitalize;
  }

  &__dateText {
    margin-top: 1.375rem;
    color: #000;
    text-align: center;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 2.875rem;

    &--small {
        width: 100%;
    }
  }

  &__service {
    display: flex;
    gap: 0.4375rem;
    width: 100%;
    color: #494949;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;

    &--large {
      width: 17.625rem;
    }
  }

  &--large {
    grid-column: span 2;
    height: 250px;

    @include xl {
      height: 460px;
    }
  }
}
