@import "./mixins.scss";

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 12px 16px;
  background-color: #094c35;
  color: #fff;

  @include sm {
    font-size: 14px;
    padding-right: 50px;
  }

  &__text {
    margin: 0;
    font-weight: 500;
  }

  &__code {
    font-weight: 700;
    margin-left: 1px;
    cursor: copy;
    border-bottom: 1px solid #fff;
    line-height: 130%;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  &__close {
    width: 28px;
    height: 28px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 16px;
    position: absolute;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.14),
      rgba(255, 255, 255, 0.04)
    );
    background-color: #094c35;
  }

  &__x {
    width: 18px;
    height: 18px;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
