.container {
  background: #fff;
  width: 100vw;
  z-index: 1000;
  overflow: auto;
  max-height: 100vh;

  &__children {
    padding: 16px;
  }

  &--withChildren {
    border-radius: 24px 24px 0px 0px;
    background: #fff;
    box-shadow: 0px -10px 30px 0px rgba(0, 0, 0, 0.06);
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -16px;
    margin-bottom: 24px;
    gap: 2px;

    span {
      color: #65ae6b;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
