@import "./mixins.scss";

.disclaimer {
  color: #7d7d7d;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.3px;
  margin-left: 14px;
  margin-bottom: 40px;

  @include md {
    margin-bottom: 10px;
    font-size: 10px;
  }

  @include sm {
    font-size: 9px;
    margin-left: 0px;
  }
}

.inputGroup {
  display: flex;
  padding: 7px 14px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 41px;
  border: 1px solid rgba(9, 76, 54, 0.06);
  margin-bottom: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);

  .originalPrice {
    color: #808080;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.6px;
    text-decoration-line: line-through;

    @include between-lg-and-xl {
      font-size: 11px;
    }

    @include md {
      font-size: 10px;
    }

    @include sm {
      font-size: 9px;
    }
  }

  &__price {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.2px;

    @include between-lg-and-xl {
      font-size: 12.5px;
    }

    @include between-sm-and-md {
      font-size: 13px;
    }

    @include lg {
      font-size: 12px;
    }

    @include sm {
      font-size: 11px;
    }
  }

  &__priceGroup {
    display: flex;
    align-items: center;
    gap: 8px;

    @include between-lg-and-xl {
      gap: 4px;
    }
  }

  &__side {
    display: flex;
    align-items: center;
    gap: 6px;

    @include between-lg-and-xl {
      gap: 3px;
    }
  }

  &__radio {
    width: 16px;
    height: 16px;
    border: 1px solid #65ae6b;
    border-radius: 50%;
    appearance: none;
    -webkit-appearance: none;
    background: #ececec33;
    margin: 0;
    display: grid;
    place-content: center;
    cursor: pointer;

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #65ae6b;
      transform: scale(0);
      transition: transform 0.2s ease-in-out;
      box-shadow: inset 1em 1em rgba(0, 0, 0, 0.2);
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  &__lbl {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.2px;
    display: flex;
    align-items: center;
    gap: 4px;

    @include between-sm-and-md {
      font-weight: 500;
      font-size: 12.5px;
    }

    @include between-lg-and-xl {
      font-size: 13px;
    }

    @include lg {
      font-size: 12px;
    }

    @include sm {
      font-size: 11px;
    }
  }
}

.lbl {
  color: #57ae61;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.2px;
  display: flex;

  @include lg {
    font-size: 12px;
  }

  @include md {
    font-size: 12px;
  }
}

.smallPill {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40.967px;
  border: 0.953px solid #65ae6b;
  background: #fff;
  margin-bottom: 16px;
  max-width: fit-content;

  &__view {
    background: #65ae6b;
    color: #fff;
    cursor: pointer;
  }

  span {
    color: #65ae6b;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15.6px;
    text-transform: uppercase;

    @include lg {
      font-size: 10px;
      line-height: 13px;
    }
  }
}

.icon {
  @include lg {
    width: 13px;
    height: 13px;
  }
}

.modalContainer {
  overflow: auto;

  h3 {
    margin: 0;
    margin-left: 8px;
  }

  ul {
    margin-top: 0;
    width: 475px;
    list-style: none;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: 250px;
  }

  ul li::before {
    content: "\2022"; 
    color: #65ae6b; 
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
    margin-left: -1em; 
  }

  li {
    font-size: 14px;
    break-inside: avoid;
    margin-top: 8px;
  }
}