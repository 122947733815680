@import "./mixins.scss";

.footer {
  padding: 5rem 8.75rem 1.25rem 8.75rem;
  background-color: #686868;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;

  @include lg {
    padding: 5rem 5.5rem 1.25rem 5.5rem;
  }

  .largeSeparator {
    width: 14.5313rem;
    border-bottom: 0.0625rem solid #ffffff1f;
    height: 0.0625rem;
  }

  &__top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #ffffff;
  }

  &__topRight {
    display: flex;
    gap: 80px;

    h3 {
      margin: 0;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.5125rem;

      @include lg {
        font-size: 1.125rem;
        line-height: 1.6875rem;
      }
    }
  }

  &__topLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 11.775rem;
  }

  .logo {
    img {
      width: 14.5313rem;
      height: 2.325rem;
    }
  }

  .navigation {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;
  }

  .contactInfo {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    span {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;

      @include lg {
        font-size: 0.875rem;
        line-height: 1.3125rem;
      }
    }
  }

  .socialMedia {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;

    img {
      width: 20px;
      height: 20px;
    }

    &__followUs {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;

      @include lg {
        font-size: 0.875rem;
        line-height: 1.3125rem;
      }
    }
  }

  .copyright {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #ffffff80;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    display: flex;
    gap: 24px;

    a {
      text-decoration: none;
      color: #ffffff80;

      &:hover {
        color: #65ae6b;
      }
    }

    @include lg {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    &__separator {
      border-left: 0.0625rem solid #ffffff3d;
      height: 1.3125rem;
    }
  }
}

a {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;

  &:hover {
    color: #65ae6b;
  }

  @include lg {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
}
