@import "./mixins.scss";

.lawnArea {
  display: flex;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 1.125rem;
  border-radius: 1.875rem;
  border: 6px solid rgba(9, 76, 54, 0.1);
  backdrop-filter: blur(2px);
  width: fit-content;
  margin-bottom: 2.5rem;

  @include lg {
    font-size: 13.25px;
    margin-bottom: 1.5rem;
  }

  @include md {
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 18px;
    border-radius: 30px;
    border: 6px solid rgba(9, 76, 54, 0.1);
    background: #fff;
    backdrop-filter: blur(2px);
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    color: #094c36;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.6px;
    letter-spacing: -0.24px;
  }
}
