@import "./mixins.scss";

.addressPage {
  display: flex;
  padding: 2.8125rem 2.5rem 2.8125rem 2.5rem;
  background-color: #ffffff;
  border-radius: 1.5rem;
  gap: 3.75rem;

  @include md {
    border-radius: 0;
    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  .alert {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 8px;
    background: rgba(157, 195, 161, 0.22);
    color: #094c36;
    text-align: top;
    margin-bottom: 2rem;

    &__icon {
      width: 1.5rem;
      height: 1.5rem;

      @include lg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    &__text {
      flex: 1 0 0;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.1375rem;
      letter-spacing: -0.0163rem;
      margin: 0;

      @include lg {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include md {
      flex-direction: column;
      gap: 10px;
    }

    &__separator {
      color: #727272;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &__btn {
      display: flex;
      padding: 1rem 3rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      background: #65ae6b;
      border: none;
      color: #fff;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &--other {
        background: rgba(101, 174, 107, 0.15);
        color: #65ae6b;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      @include md {
        width: 100%;
      }

      @include xl {
        padding: 1rem 1.5rem;
        font-size: 14px;
      }

      @include lg {
        font-size: 13px;
        padding: 1rem 0.75rem;
      }
    }
  }

  .freeQuote {
    border-radius: 2.6875rem;
    padding: 0rem 0.75rem 0rem 0.75rem;
    color: #65ae6b;
    border: solid 2px #65ae6b;
    font-weight: 700;
    font-size: 0.8125rem;
    line-height: 1.0562rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    @include lg {
      font-size: 10px;
      line-height: 13px;
    }

    @include md {
      padding: 3px 8px;
      gap: 3.604px;
      border-radius: 25.83px;
      border: 1px solid #65ae6b;
      font-size: 10px;
      line-height: 13px;
    }
  }

  .infoSection {
    flex: 1;
    display: flex;
    flex-direction: column;

    @include md {
      padding: 32px 16px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 13px;
      margin-bottom: 2rem;
    }

    h1 {
      margin: 0;
      font-weight: 600;
      font-size: 47px;
      line-height: 51.7px;
      text-align: center;
      color: #1e1e1e;
      letter-spacing: -0.94px;

      @include lg {
        font-size: 40px;
        line-height: 44px;
        letter-spacing: -0.8px;
      }

      @include md {
        font-size: 24px;
        line-height: 26.4px;
        letter-spacing: -0.48px;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 2.5rem;

      &__error {
        color: #f00;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.3px;
        letter-spacing: -0.26px;
        margin-top: -7px;
      }

      &__areaLabel {
        color: #094c36;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 14.3px;
        letter-spacing: -0.26px;
      }

      &__areaBox {
        margin-top: -5px;
        margin-bottom: -10px;
        display: flex;
        align-items: center;

        ol {
          width: 280px;
          margin: 0;
          padding: 0;
          margin-left: 15px;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          color: #094c36;
        }
      }

      &__areaIcon {
        color: #094c36;
        margin-left: 4px;
        cursor: pointer;
      }

      &__areaImg {
        width: 300px;
        border-radius: 8px;
      }

      &__areaTooltip {
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        z-index: 10000;
      }

      input {
        display: flex;
        padding: 1.0625rem 1rem;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #dcdcdc;
        background: #fff;
        height: auto;
        font-size: 13px;
      }
    }
  }

  .mapSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include md {
      height: 50vh;
      flex: auto;
      width: 100vw;
    }

    .mapContainer {
      flex: 1 0 0;
      align-self: stretch;
      position: relative;
      height: 100%;

      @include md {
        height: 100%;
      }
    }
  }

  .lawnArea {
    display: flex;
    padding: 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 1.125rem;
    border-radius: 1.875rem;
    border: 6px solid rgba(9, 76, 54, 0.1);
    backdrop-filter: blur(2px);
    width: fit-content;
    margin-bottom: 2.5rem;
  }

  .mapState {
    display: inline-flex;
    padding: 0.1875rem 0.875rem 0.1875rem 0.3125rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    border-radius: 43px;
    background: #64b167;
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;

    img {
      width: 1.5rem;
      height: 1.5rem;

      @include lg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    span {
      color: #fff;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 16.9px;
      text-transform: uppercase;

      @include lg {
        font-size: 10px;
        line-height: 1rem;
      }

      @include md {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 20px;
  gap: 24px;

  .modalHeading {
    color: #1e1e1e;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.8px;

    @include lg {
      font-size: 32px;
      line-height: 35.2px;
      letter-spacing: -0.64px;
    }

    @include md {
      font-size: 24px;
      line-height: 26.4px;
      letter-spacing: -0.48px;
    }

    @include sm {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: -0.4px;
    }
  }

  .modalText {
    color: #000;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.5px;
    letter-spacing: -0.38px;
    max-width: 60%;
    margin-bottom: 16px;

    @include lg {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.32px;
      max-width: 80%;
    }

    @include md {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.28px;
      max-width: 100%;
    }

    @include sm {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.24px;
    }
  }

  .modalButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
  }
}

.inputWithUnit {
  position: relative;

  &::after {
    content: "sqft";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
