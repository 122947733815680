@import "./mixins.scss";

.checkoutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 1.5rem;
  background: #fff;
  max-width: 1250px;
  box-shadow: 0px 3.89911px 12.67211px 0px rgba(36, 78, 46, 0.16);
  margin-bottom: 3.75rem;

  @include md {
    grid-template-columns: 1fr;
    border-radius: 0;
    max-width: 100vw;
    width: 100%;
    margin-bottom: 0;
  }

  .leftSide {
    padding: 45px 30px 45px 40px;
    display: flex;
    flex-direction: column;
    padding-right: 1.875rem;

    @include md {
      padding: 16px;
    }

    @include sm {
      padding: 4px;
    }

    input {
      margin-bottom: 1rem;
      margin-top: 0.25rem;

      display: flex;
      padding: 17px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      font-size: 13px;

      border-radius: 8px;
      border: 1px solid #dcdcdc;
      background: #fff;

      width: 100%;

      &:focus {
        outline: 1px solid #65ae6b;
      }
    }

    label {
      color: #094c36;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .rightSide {
    background: rgba(236, 236, 236, 0.2);
    padding: 45px 40px 45px 30px;
    border-radius: 0 1.5rem 1.5rem 0;
    display: flex;
    flex-direction: column;

    @include md {
      padding: 16px;
      background: #fff;
    }

    @include sm {
      padding: 8px;
    }

    &__smallPill {
      display: flex;
      padding: 4px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 40.967px;
      border: 0.953px solid rgba(101, 174, 107, 0.3);
      background: #fff;
      margin-bottom: 16px;
      max-width: fit-content;

      span {
        color: #65ae6b;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 15.6px;
        text-transform: uppercase;
      }
    }

    &__address {
      color: #000;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 14.3px;
      letter-spacing: -0.26px;
      width: fit-content;
      margin-bottom: 32px;
    }

    &__inputWrapper {
      display: flex;
      margin-bottom: 40px;

      @include md {
        margin-bottom: 13px;
      }

      input {
        display: flex;
        padding: 17px 16px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px 0px 0px 8px;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        border-left: 1px solid #dcdcdc;
        background: #fff;
        color: #989898;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.3px;
        letter-spacing: -0.26px;
        border-right: none;
      }
    }

    &__submitBtn {
      display: flex;
      width: 156px;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      border-radius: 0px 8px 8px 0px;
      background: #65ae6b;
      color: #fff;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
      cursor: pointer;

      @include sm {
        width: fit-content;
        padding: 15px 20px;
      }

      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &__selectedServices {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      width: fit-content;
      margin-bottom: 22px;

      .ss__header {
        color: #65ae6b;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 24.7px;
        text-transform: uppercase;
      }

      .ss__num {
        color: #000;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: 24.7px;
        text-transform: uppercase;
      }
    }

    &__service {
      display: flex;
      gap: 25px;
      align-items: center;
      margin-bottom: 13px;

      .service__name {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20.8px;
      }
    }

    &__dashLine {
      border-bottom: 1.275px dashed rgba(0, 0, 0, 0.1);
      flex: 1;
    }

    &__greenDivider {
      height: 1.275px;
      background: #65ae6b;
      width: 100%;
      margin: 32px 0;
    }
  }
}

.backButton {
  padding: 1rem 1.5625rem;
  gap: 8px;
  border: 2px solid #fff;
  background: transparent;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.specialButton {
  border: none;
  display: flex;
  align-items: center;
  background: transparent;
  color: #65ae6b;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.0562rem;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

#ccn {
  justify-content: space-between;
}

.inputWrapper {
  margin-bottom: 1rem;
  margin-top: 0.25rem;

  display: flex;
  padding: 17px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #dcdcdc;
  background: #fff;

  justify-content: space-between;
}

.inputField {
  flex-grow: 1;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.inputImages {
  display: flex;
  gap: 0.25rem;
}

.cardInfo {
  display: flex;
  align-self: stretch;
  gap: 1rem;
  align-items: center;

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;

    input {
      flex: 1;
    }
  }
}

.submitButton {
  display: flex;
  padding: 1rem 2.5rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #65ae6b;
  border: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.formAlert {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;

  span {
    flex: 1 0 0;
    color: #7d7d7d;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.8938rem;
  }
}

.specialInput {
  display: flex;
  height: 96px;
  padding: 17px 16px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  background: #fff;
  margin-bottom: 40px;
  margin-top: 4px;
  letter-spacing: -0.26px;
  font-weight: 400;
  line-height: 14.3px;
  font-size: 13px;
  font-style: normal;
  color: #000;

  &:focus {
    outline: 1px solid #65ae6b;
  }

  &::placeholder {
    color: #989898;
  }
}

.greenDivider {
  width: 100%;
  height: 1px;
  background: #65ae6b;
  margin-top: 24px;
  margin-bottom: 8px;
}

.modalContainer {
  display: flex;
  flex-direction: column;;
  padding: 25px 20px;
  gap: 20px;
  overflow-y: auto;
  max-height: 80vh;

  @include sm {
    padding: 10px;
  }

  &__heading {
    margin: 0;
    text-align: center;
    width: 100%;

    @include sm {
      font-size: 16px;
    }
  }

  p {
    margin: 0;
    font-size: 14px;

    @include sm {
      font-size: 13px;
    }
  }
}

.searchBoxDiv {
  input {
    padding: 28px 16px !important;
  }
}

.couponText {
  color: #65ae6b;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.9px;
  letter-spacing: -0.26px;

  @include md {
    margin-bottom: 26px;
  }
}
