@import "./mixins.scss";

.servicesPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include md {
    background: #fff;
  }
}

.container {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 3.89911px 12.67211px 0px rgba(36, 78, 46, 0.16);
  padding: 45px 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-self: stretch;
  gap: 20px;

  @include md {
    grid-template-columns: 1fr;
    border-radius: 0;
    padding: 24px 16px;
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--dark {
      background: #fbfbfb;
      margin: -45px -40px -45px 0;
      border-radius: 24px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      align-items: flex-start;
      padding: 45px 40px 45px 30px;
      justify-content: flex-start;
    }
  }

  .subText {
    display: flex;
    height: 28px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 43px;
    border: 2px solid #65ae6b;

    color: #65ae6b;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.9px;
    text-transform: uppercase;
    width: fit-content;

    @include lg {
      font-size: 12px;
      line-height: 15px;
    }
  }

  h1 {
    color: #1e1e1e;
    text-align: center;
    font-size: 47px;
    font-style: normal;
    font-weight: 600;
    line-height: 51.7px;
    letter-spacing: -0.94px;
    margin-top: 13px;

    @include lg {
      font-size: 40px;
      line-height: 44px;
      letter-spacing: -0.8px;
    }
  }
}

.addressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 16px;

  &__smallPill {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40.967px;
    border: 0.953px solid rgba(101, 174, 107, 0.3);
    background: #fff;
    max-width: fit-content;

    span {
      color: #65ae6b;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 15.6px;
      text-transform: uppercase;
    }
  }

  &__address {
    color: #000;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14.3px;
    letter-spacing: -0.26px;
  }
}

.mapContainer {
  height: 400px;
  width: auto;
  align-self: stretch;
  position: relative;
  border-radius: 22px;
  border: 1px solid rgba(101, 174, 107, 0.2);
}

.backButton {
  padding: 1rem 1.5625rem;
  gap: 8px;
  border: 2px solid #fff;
  background: transparent;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 60px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.continueBtn {
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #65ae6b;
  border: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 27px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.menuBar {
  &__priceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
  }

  &__totalPrice {
    color: #094c36;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__monthlyTotalPrice {
    color: #888;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.3px;
  }

  &__priceDetailsBtn {
    color: #65ae6b;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.3px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-right: 0;
  }

  &__continueBtn {
    display: flex;
    width: 100%;
    padding: 16px 48px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #65ae6b;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 16px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.disclaimer {
  color: #7d7d7d;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.3px;
  margin-bottom: 40px;
}


.noScroll {
  overflow: hidden;
}