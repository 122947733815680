@import './mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 20px;
  max-height: 90vh;
  overflow: auto;

  &__pill {
    display: flex;
    height: 28px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 43px;
    border: 2px solid #65ae6b;
    color: #65ae6b;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.9px;
    text-transform: uppercase;
    margin-bottom: 24px;

    @include md {
      font-size: 12px;
    }
  }

  &__title {
    color: #000;
    font-size: 51px;
    font-style: normal;
    font-weight: 600;
    line-height: 56.1px;
    letter-spacing: -1.02px;
    margin-bottom: 24px;

    @include md {
      font-size: 36px;
      line-height: 44px;
      text-align: center;
    }
  }

  &__text {
    color: #000;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.5px;
    letter-spacing: -0.38px;
    max-width: 50%;
    margin-bottom: 40px;

    @include md {
      font-size: 16px;
      line-height: 24px;
      max-width: 100%;
    }
  }

  &__items {
    display: inline-flex;
    align-items: flex-start;
    gap: 30px;
    flex-shrink: 0;
    margin-bottom: 30px;
    max-width: 100%;
    overflow: auto;
    padding-top: 20px;
  }

  &__btn {
    display: inline-flex;
    padding: 16px 56px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
    border-radius: 8px;
    background: #65ae6b;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:hover {
      opacity: 0.8;
    }
  }
}
